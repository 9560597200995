import React from "react"
import { graphql } from "gatsby"
import { linkResolver } from "../prismic/linkResolver"
import Wrapper from "../components/Wrapper"
import Grid from "../components/Grid"
import Img from "../components/CustomImg"
import Button from "../components/Button"
import AspectRatio from "../components/AspectRatio"
import { Link } from "gatsby"

export const query = graphql`
  query {
    prismic {
      page(uid: "styleraad", lang: "da-dk") {
        title
      }
    }
  }
`

const Raadgivning = ({ data }) => {
  const title = "Styleråd"
  const heading = "Vi hjælper dig på vej"

  return (
    <>
      <div className="slice feature">
        <Wrapper>
          <Grid>
            <div className="text-col">
              <div className="paragraph-max-width">
                <h2 className="fs--s mb--xs tt--u co--light">{title}</h2>
                <h3 className="fs--m mb--s lh--s">{heading}</h3>
                <div className="co--light lh--m mb--l article">
                  Vi står klar til at hjælpe dig og rådgive dig, så du kan få de
                  flotteste nye gardiner.
                </div>
                <div>
                  <Link to="/kalender/">
                    <Button
                      classList="consultancy-button"
                      icon="chevron-right"
                      text="Book et styling møde"
                    />
                  </Link>
                </div>
                <div>
                  <Link to="/tilbud/">
                    <Button
                      classList="consultancy-button"
                      icon="chevron-right"
                      text="Få et tilbud"
                    />
                  </Link>
                </div>
                <div>
                  <Link to="/stofproever/">
                    <Button
                      classList="consultancy-button"
                      icon="chevron-right"
                      text="Bestil stofprøver"
                    />
                  </Link>
                </div>
                <div>
                  <Link to="/erhverv/">
                    <Button
                      classList="consultancy-button"
                      icon="chevron-right"
                      text="Erhverv / Større projekter"
                    />
                  </Link>
                </div>
                <div className="consultancy-contact">
                  Har du brug for hjælp med andet, kan du gå til&nbsp;
                  <Link to="/kontakt/">kontakt os siden</Link>.
                </div>
              </div>
            </div>

            <div className="image-col">
              <AspectRatio ratio="portrait">
                <Img src="https://images.prismic.io/anddrape/e35b3672-b313-489e-9a21-0342bd2642df__15616.jpg?auto=compress,format&rect=0,0,2560,3413&w=2048&h=2730  " />
              </AspectRatio>
            </div>
          </Grid>
        </Wrapper>
      </div>
    </>
  )
}

export default Raadgivning
